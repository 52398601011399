<template>
  <sub-page
      :title="$t('CrmSystem')"
      icon="mdi-card-account-phone-outline"
      v-model="$store.state.settings.pageData"
  >

    <ws-accordion
        :items="navigationItems"
        class="mt-5"
    >
      <template #action.custom_fields>

        <v-btn @click.stop="openNewCustomField" :color="wsATTENTION" dark  class="noCaps mr-4">
          <v-icon>mdi-plus</v-icon>
          {{ $t('Add') }}
        </v-btn>

      </template>
      <template #item.custom_fields>

        <ws-data-table
            v-if="customFields.length > 0"
            :items="customFields"
            :headers="customFieldsHeaders"
            :row-action="openCustomFieldEdit"
        >
          <template #item.name="{item}">
            <div class="d-flex">
              <ws-chip :icon="getIcon(item.type)" :color="item.is_visible ? wsATTENTION : wsACCENT" :text="item.name"></ws-chip>
              <v-icon class="ml-1" small v-if="item.is_visible" :color="wsATTENTION">mdi-account-edit</v-icon>
            </div>
          </template>
          <template #item.type="{item}">
            <h5> {{  $t('crm.custom_field.' + item.type ) }}</h5>
          </template>
          <template #item.action="{item}">
            <div class="d-flex">
              <v-btn @click.stop="openCustomFieldEdit(item)" icon :color="wsACCENT">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn @click.stop="openDeleteCustomField(item)" icon :color="wsACCENT">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>

          </template>

        </ws-data-table>
        <div v-else class=" py-6 px-4">
          <h4 class="text-center font-weight-medium ">{{ $t('NoCustomFieldsYet') }}</h4>
        </div>

      </template>

    </ws-accordion>

    <template #dialog>
      <ws-dialog
          v-model="displayCustomFieldDialog"
          :title="$t('AddCustomField')"
          @save="saveCustomField"
          :width="customFieldEntityData.type === 'select' ? 900 : 600"
          :show-delete="!newCustomField"
          :confirm-delete-text="$t('crm.custom_field.delete')"
          :display-confirm-delete="displayConfirmDeleteCustomField"
          @delete="deleteCustomField"

      >

        <div class="pa-1">
          <ws-text-field
              v-model="customFieldEntityData.name"
              :label="$t('Name')"
              :placeholder="$t('EnterCustomFieldName')"
          />
          <ws-select
              class="mt-3"
              v-model="customFieldEntityData.type"
              :items="customFieldsTypes"
              :placeholder="$t('ChooseCustomFieldType')"
              :label="$t('Type')"
          />

          <v-expand-transition>
            <div v-if="customFieldEntityData.type === 'select'">
              <div class="d-flex justify-space-between align-center">
                <h5 class="mt-5">{{ $t('CustomFieldSelectItems') }}</h5>
                <!-- <ws-switch :label="$t('differentValues')" v-model="customFieldEntityData.select_items_different"></ws-switch>-->
              </div>
              <v-row class="pt-5" >
                <v-col>
                  <v-sheet max-height="400" class="overflow-y-auto pb-1">
                    <ws-text-field
                        v-model="customFieldEntityData.select_items_text"
                        :label="$t('Name')"
                        :placeholder="$t('EnterCustomFieldItems')"
                        area
                        lined
                    />
                  </v-sheet>

                </v-col>
                <v-col v-if="customFieldEntityData.select_items_different">
                  <v-sheet max-height="400" class="overflow-y-auto">
                    <ws-text-field
                        v-model="customFieldEntityData.select_items_value"
                        :label="$t('Value')"
                        :placeholder="$t('EnterCustomFieldValue')"
                        area
                        lined
                    />
                  </v-sheet>
                </v-col>
              </v-row>
            </div>

          </v-expand-transition>

          <ws-switch
              class="mt-5"
              v-model="customFieldEntityData.is_visible"
              :placeholder="$t('CustomFieldVisibleToClient')"
              :tooltip="$t('CustomFieldVisibleToClientTooltip')"
          />


        </div>

      </ws-dialog>
    </template>

  </sub-page>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "settingsCrm",
  data() {
    return {
      customFields : [],
      customFieldEntityData : {},
      displayCustomFieldDialog : false,
      displayConfirmDeleteCustomField : false,
      newCustomField : false
    }
  },
  computed : {
    customFieldsTypes() {
      return [
        { text : this.$t('Text')           ,   value : 'text'       },
        { text : this.$t('ChooseFromList') ,   value : 'select'     },
        // { text : this.$t('TextArea')    ,   value : 'textarea'   },
        // { text : this.$t('Email')       ,   value : 'email'      },
        { text : this.$t('Link')           ,   value : 'link'       },
        // { text : this.$t('Phone')       ,   value : 'phone'      },
        // { text : this.$t('Number')      ,   value : 'number'     },
        { text : this.$t('BooleanType')    ,   value : 'boolean'    },

      ]
    },
    customFieldsHeaders() {
      return [
        { text : this.$t('Name') , value : "name" },
        { text : this.$t('Type') , value : "type" },
        { value : "action" , align : 'right' , width : '10px' }
      ]
    },
    navigationItems() {
      return [
        { name : this.$t('CustomFields') ,
          subtitle : this.$t('CustomFieldsSettings') ,
          value : 'custom_fields' ,
          icon : 'mdi-pencil-box-multiple-outline' ,
          expanded : true
        }
      ]
    }
  },
  methods : {
    ...mapActions('crmSystem', [
        'GET_CRM_SETTINGS',
        'ADD_CUSTOM_FIELD',
        'DELETE_CUSTOM_FIELD'
    ]),
    async saveChanges() {

    },
    async saveCustomField() {
      let result = await this.ADD_CUSTOM_FIELD(this.customFieldEntityData)
      if ( !result ) {
        return
      }
      if ( this.newCustomField ) {
        this.customFields.push(result)
      } else {
        let index = this.customFields.findIndex( el=> el.id === result.id )
        if ( index === -1 )  {
          return
        }
        this.$set(this.customFields, index , result )
      }
      this.displayCustomFieldDialog = false
      this.notify(this.$t('ChangesSaved'))
    },
    async deleteCustomField() {
      let result = await this.DELETE_CUSTOM_FIELD(this.customFieldEntityData.id)
      if ( !result ) {
        return
      }
      let index = this.customFields.findIndex( el=> el.id === this.customFieldEntityData.id )
      if ( index === -1 )  {
        return
      }
      this.customFields.splice(index , 1)
      this.displayCustomFieldDialog = false
      this.notify(this.$t('CustomFieldDeleted'))
    },
    getIcon(type) {
      let icons = {
        select : 'mdi-form-select',
        text : 'mdi-form-textbox',
        boolean : 'mdi-order-bool-ascending',
        link : 'mdi-link-variant'
      }
      return icons[type] ? icons[type] : 'mdi-form-textbox'
    },
    openCustomFieldEdit(item) {
      this.newCustomField = false
      this.displayConfirmDeleteCustomField = false
      this.customFieldEntityData = JSON.parse(JSON.stringify(item))

      if ( item.type === 'select' ) {
        this.customFieldEntityData.select_items_text = ''
        item.items.forEach((el,index) => {
          this.customFieldEntityData.select_items_text += el.text + ( index < (item.items.length -1) ? "\n" : "" )
        })

      }

      this.displayCustomFieldDialog = true
    },
    openNewCustomField(item) {
      this.displayConfirmDeleteCustomField = false
      this.newCustomField = true
      this.customFieldEntityData = JSON.parse(JSON.stringify(item))
      this.displayCustomFieldDialog = true
    },
    openDeleteCustomField(item) {
      this.newCustomField = true
      this.displayConfirmDeleteCustomField = true
      this.customFieldEntityData = JSON.parse(JSON.stringify(item))
      this.displayCustomFieldDialog = true
    }
  },
  async mounted() {
    let result = await this.GET_CRM_SETTINGS()
    if ( !result ) {
      return
    }
    this.customFields = result.custom_fields
  }
}
</script>

<style scoped>

</style>